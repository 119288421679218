import * as React from "react";
import Footer from "../components/Footer";
import Timer from "../components/Countdown";
import { Helmet } from "react-helmet";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Emc = () => {
  return (
    <>
      <Helmet>
        <title>
          Consult FGC | Digital Marketing Agency | Website Design & Development
        </title>
        <link
          rel="icon"
          type="image/x-icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        ></link>
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://consultfgc.com/"
        ></link>
        <meta data-react-helmet="true" charset="utf-8"></meta>
        <meta
          data-react-helmet="true"
          name="description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          data-react-helmet="true"
          name="robots"
          contet="max-snippet:-1, max-image-preview:large, max-video-pewview:-1"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:locale"
          content="en-US"
        ></meta>
        <meta data-react-helmet="true" property="og:type" content="page"></meta>
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://consultfgc.com/"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <meta
          data-react-helmet="true"
          property="article:tag"
          content="geofencing , geofencing advertising , true-scrope"
        ></meta>
        <meta
          data-react-helmet="true"
          property="face-domain-verification"
          content="ivt9yr7ijq3ruqyq8nt02ol48zm4mu"
        ></meta>
        <link
          href="https://fonts.googleapis.com/css?family-Poppins:400,500,600,700,800&display=swap"
          rel="stylesheet"
        ></link>
        <script
          src="https://connect.facebook.net/signals/config/793714948042216?v=2.9.83&r=stable"
          async
        ></script>
      </Helmet>

      <div class="bg-white">
        <header class="absolute inset-x-0 top-10 z-50">
          <nav class="max-w-7xl mx-auto" aria-label="Top">
            <div class="w-full flex items-center justify-center border-b border-indigo-500 lg:border-none">
              <div class="flex items-center">
                <a href="/">
                  <span class="sr-only">Consult FGC</span>
                  <img
                    class="h-20 w-auto hover:opacity-70"
                    src="https://imgix.cosmicjs.com/7baab430-10f9-11ee-af69-111aab27c387-Logo-2022-Large-White.png"
                    alt=""
                  />
                </a>
              </div>
              <a
                href="tel:+18883417735"
                class="border-l border-white pl-6 ml-6 inline-flex text-lg font-semibold text-[#ebffcd] hover:underline"
              >
                <svg
                  class=" h-7 w-7 text-green-400"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                  fill="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                  />
                </svg>{" "}
                +1 (888) 341-7735
              </a>
            </div>{" "}
          </nav>
        </header>

        <main>
          <div class="relative isolate overflow-hidden bg-gray-900 pb-16 pt-14 sm:pb-20">
            <img
              src="https://imgix.cosmicjs.com/d2fcda40-6123-11ee-b975-cb0cfadd93ad-bg-edited.jpg"
              alt=""
              class="absolute inset-0 -z-10 h-full w-full object-cover"
            />
            <div
              class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
              aria-hidden="true"
            ></div>
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
              <div class="mx-auto py-32 sm:py-48 lg:py-56">
                <div class="text-center">
                  <h1 class="text-4xl font-medium tracking-tight text-[#bdf46c] uppercase sm:text-6xl">
                    Digital Solutions to{" "}
                    <span class="block pt-2">supercharge your business</span>
                  </h1>
                  <p class="max-w-4xl mx-auto mt-6 text-2xl leading-8 text-gray-300">
                    Offering full-service digital advertising and marketing
                    services that reduce overall CTA and churn, generate sticky
                    customers, and provide valuable customer insights.
                  </p>
                  <div class="hidden mt-10 lg:flex lg:items-center lg:justify-center gap-x-6">
                    <a
                      href="/contact-us/"
                      class="rounded-md bg-blue-700 px-8 py-4 text-xl font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                    >
                      Get Started Today
                    </a>
                    <a
                      href="mailto:marketing@consultfgc.com"
                      class="lg:mt-0 block text-xl font-semibold text-gray-300 shadow-sm hover:underline"
                    >
                      Email Us Directly
                    </a>
                  </div>
                  <div class="mt-10 lg:hidden lg:items-center lg:justify-center gap-x-6">
                    <a
                      href="/contact-us/"
                      class="rounded-md bg-blue-700 px-8 py-4 text-xl font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                    >
                      Get Started Today
                    </a>
                    <a
                      href="mailto:marketing@consultfgc.com"
                      class="mt-10 lg:mt-0 block w-full text-xl font-semibold text-gray-300 shadow-sm hover:underline"
                    >
                      Email Us Directly
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="hidden lg:block relative bg-white">
            <div class="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
              <div class="">
                <div class="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
                  <div class="-mt-28 pt-6">
                    <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                      <div class="-mt-6">
                        <div>
                          <span class="inline-flex items-center justify-center rounded-xl bg-emerald-500 p-3 shadow-lg">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="white"
                              class="w-8 h-8"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                          </span>
                        </div>
                        <h3 class="mt-8 text-2xl font-semibold leading-8 tracking-tight text-yellow-500">
                          Consult FGC
                        </h3>
                        <p class="text-base font-semibold leading-7 text-gray-900">
                          Digital Marketing
                        </p>
                        <p class="text-left mt-5 text-base leading-7 text-gray-600">
                          • Digital Advertising
                        </p>
                        <p class="text-left mt-1 text-base leading-7 text-gray-600">
                          • Social Media Advertising
                        </p>
                        <p class="text-left mt-1 text-base leading-7 text-gray-600">
                          • Web Design
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="-mt-28 pt-6">
                    <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                      <div class="-mt-6">
                        <div>
                          <span class="inline-flex items-center justify-center rounded-xl bg-emerald-500 p-3 shadow-lg">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="white"
                              class="w-8 h-8"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                          </span>
                        </div>
                        <h3 class="mt-8 text-2xl font-semibold leading-8 tracking-tight text-yellow-500">
                          Hyloq
                        </h3>
                        <p class="text-base font-semibold leading-7 text-gray-900">
                          Advanced Geofencing
                        </p>
                        <p class="text-left mt-5 text-base leading-7 text-gray-600">
                          • Unparalleled Data Options
                        </p>
                        <p class="text-left mt-1 text-base leading-7 text-gray-600">
                          • TrueSpend Pricing
                        </p>
                        <p class="text-left mt-1 text-base leading-7 text-gray-600">
                          • Easy Programmatic Advertising
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="-mt-28 pt-6">
                    <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                      <div class="-mt-6">
                        <div>
                          <span class="inline-flex items-center justify-center rounded-xl bg-emerald-500 p-3 shadow-lg">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="white"
                              class="w-8 h-8"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                          </span>
                        </div>
                        <h3 class="mt-8 text-2xl font-semibold leading-8 tracking-tight text-yellow-500">
                          OpenGate TPV
                        </h3>
                        <p class="text-base font-semibold leading-7 text-gray-900">
                          Amazing TPV Technology
                        </p>
                        <p class="text-left mt-5 text-base leading-7 text-gray-600">
                          • IVR Capabilities
                        </p>
                        <p class="text-left mt-1 text-base leading-7 text-gray-600">
                          • Text SMS
                        </p>
                        <p class="text-left mt-1 text-base leading-7 text-gray-600">
                          • Digital Third Party Verification
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="block lg:hidden relative bg-white">
            <div class="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
              <div class="">
                <div class="grid grid-cols-1 gap-12">
                  <div class="-mt-28 pt-6">
                    <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                      <div class="-mt-6">
                        <div>
                          <span class="inline-flex items-center justify-center rounded-xl bg-emerald-500 p-3 shadow-lg">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="white"
                              class="w-8 h-8"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                          </span>
                        </div>
                        <h3 class="mt-8 text-2xl font-semibold leading-8 tracking-tight text-yellow-500">
                          Consult FGC
                        </h3>
                        <p class="text-base font-semibold leading-7 text-gray-900">
                          Digital Marketing
                        </p>
                        <p class="text-left mt-5 text-base leading-7 text-gray-600">
                          • Digital Advertising
                        </p>
                        <p class="text-left mt-1 text-base leading-7 text-gray-600">
                          • Social Media Advertising
                        </p>
                        <p class="text-left mt-1 text-base leading-7 text-gray-600">
                          • Web Design
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="mt-4 pt-6">
                    <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                      <div class="-mt-6">
                        <div>
                          <span class="inline-flex items-center justify-center rounded-xl bg-emerald-500 p-3 shadow-lg">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="white"
                              class="w-8 h-8"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                          </span>
                        </div>
                        <h3 class="mt-8 text-2xl font-semibold leading-8 tracking-tight text-yellow-500">
                          Hyloq
                        </h3>
                        <p class="text-base font-semibold leading-7 text-gray-900">
                          Advanced Geofencing
                        </p>
                        <p class="text-left mt-5 text-base leading-7 text-gray-600">
                          • Unparalleled Data Options
                        </p>
                        <p class="text-left mt-1 text-base leading-7 text-gray-600">
                          • TrueSpend Pricing
                        </p>
                        <p class="text-left mt-1 text-base leading-7 text-gray-600">
                          • Easy Programmatic Advertising
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="mt-4 pt-6">
                    <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                      <div class="-mt-6">
                        <div>
                          <span class="inline-flex items-center justify-center rounded-xl bg-emerald-500 p-3 shadow-lg">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="white"
                              class="w-8 h-8"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                          </span>
                        </div>
                        <h3 class="mt-8 text-2xl font-semibold leading-8 tracking-tight text-yellow-500">
                          OpenGate TPV
                        </h3>
                        <p class="text-base font-semibold leading-7 text-gray-900">
                          Amazing TPV Technology
                        </p>
                        <p class="text-left mt-5 text-base leading-7 text-gray-600">
                          • IVR Capabilities
                        </p>
                        <p class="text-left mt-1 text-base leading-7 text-gray-600">
                          • Text SMS
                        </p>
                        <p class="text-left mt-1 text-base leading-7 text-gray-600">
                          • Digital Third Party Verification
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="bg-gray-50 pt-10 pb-4 lg:pb-0 lg:pt-0 lg:bg-white mt-28 sm:mt-20">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
              <div class="pt-3 mx-auto max-w-4xl sm:text-center">
                <h2 class="text-base font-semibold leading-7 text-blue-600">
                  1:10pm - 1:40pm Breakout Session #3 - Sponsored by Consult
                  F.G.C
                </h2>
                <p class="pt-4 text-3xl font-bold tracking-tight text-yellow-500 sm:text-5xl">
                  Navigating The New Energy Frontier
                </p>
                <p class="mt-6 text-lg leading-8 text-gray-600">
                  Make sure to catch our presentation on Acquiring Customers
                  Through Safe and Scalable Digital Advertising at the Energy
                  Marketing Conference.
                </p>
              </div>
              <div class="pt-8 pb-8">
                <Timer />
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
};
export default Emc;
