import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
const Timer5 = () => {
  const [countDownTime, setCountDownTIme] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  var countDownDate = new Date("Oct 17, 2023 13:10:00").getTime();

  // Update the count down every 1 second
  var x = setInterval(function () {
    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    setCountDownTIme({
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    });
    console.log(days, hours, minutes, seconds);
    if (distance < 0) {
      clearInterval(x);
      setCountDownTIme({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
    }
  }, 1000);
  return (
    <>
      <div className="">
        <div className="flex flex-col items-center justify-center w-full h-full gap-8 sm:gap-16">
          <div className="flex justify-center gap-3 sm:gap-8">
            <div className="flex flex-col gap-5 relative">
              <div className="h-16 w-16 sm:w-32 sm:h-32 lg:w-40 lg:h-40 flex justify-between items-center bg-blue-500 rounded-lg">
                <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-blue-200"></div>
                <span className="lg:text-7xl sm:text-6xl text-3xl font-semibold text-white">
                  {countDownTime?.days}
                </span>
                <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-blue-200"></div>
              </div>
              <span className="text-[#8486A9] text-xs sm:text-2xl text-center capitalize">
                {countDownTime?.days == 1 ? "Day" : "Days"}
              </span>
            </div>
            <div className="flex flex-col gap-5 relative">
              <div className="h-16 w-16 sm:w-32 sm:h-32 lg:w-40 lg:h-40 flex justify-between items-center bg-blue-500 rounded-lg">
                <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-blue-200"></div>
                <span className="lg:text-7xl sm:text-6xl text-3xl font-semibold text-white">
                  {countDownTime?.hours}
                </span>
                <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-blue-200"></div>
              </div>
              <span className="text-[#8486A9] text-xs sm:text-2xl text-center font-medium">
                {countDownTime?.hours == 1 ? "Hour" : "Hours"}
              </span>
            </div>
            <div className="flex flex-col gap-5 relative">
              <div className="h-16 w-16 sm:w-32 sm:h-32 lg:w-40 lg:h-40 flex justify-between items-center bg-blue-500 rounded-lg">
                <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-blue-200"></div>
                <span className="lg:text-7xl sm:text-6xl text-3xl font-semibold text-white">
                  {countDownTime?.minutes}
                </span>
                <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-blue-200"></div>
              </div>
              <span className="text-[#8486A9] text-xs sm:text-2xl text-center capitalize">
                {countDownTime?.minutes == 1 ? "Minute" : "Minutes"}
              </span>
            </div>
            <div className="flex flex-col gap-5 relative">
              <div className="h-16 w-16 sm:w-32 sm:h-32 lg:w-40 lg:h-40 flex justify-between items-center bg-blue-500 rounded-lg">
                <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-blue-200"></div>
                <span className="lg:text-7xl sm:text-6xl text-3xl font-semibold text-white">
                  {countDownTime?.seconds}
                </span>
                <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-blue-200"></div>
              </div>
              <span className="text-[#8486A9] text-xs sm:text-2xl text-center capitalize">
                {countDownTime?.seconds == 1 ? "Second" : "Seconds"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Timer5;
